import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import expenseimg from '../../../../src/assets/images/expense.png'
import useFetchData from '../../../hooks/useFetchData';

const ThisMonthExpenses = () => {
    const { items: expense } = useFetchData("https://servers.clearsoftwares.net/rabbi-electric/getthismonthexpenses");
    const TotalExpense = expense?.reduce((total, currentValue) => total + parseInt(currentValue?.amount), 0);
    

    return (
        <div class="mx-auto w-11/12 bg-coral text-black rounded-lg overflow-hidden shadow-lg glassmorphism">
            <div className='grid grid-cols-12'>
                <div class="col-span-5 w-full">
                    <img src={expenseimg} alt="Card image" class="mx-auto my-auto h-28 w-28 md:h-full md:w-full rounded-full border-4 border-red-600 object-cover" />
                </div>
                <div class="col-span-6 my-auto">
                    <div class="text-lg font-semibold mb-2">This Month Expenses</div>
                    <p class="text-xl text-black">{(TotalExpense)?.toFixed(2)} TK</p>
                </div>
            </div>
        </div>
    );
};

export default ThisMonthExpenses;