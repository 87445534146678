import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


const api = createApi({
    reducerPath:'api',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://servers.clearsoftwares.net/rabbi-electric/' }),
    tagTypes:[],
    endpoints: () => ({ }),
})

export default api;