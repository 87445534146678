import logo from '../../assets/images/logo.jpg'

const BusinessInfo = {
    name: "Rabbi Electric",
    fullName: "M/S Rabbi Enterprise and Electric",
    dealer: "(Proprietor: Md. Uzzal Sheikh)",
    address: "Bazar Road, Mawna Chowrasta, Sreepur, Gazipur",
    mobile: "Mobile: 01742-969645,01914-044194",
    img: logo,
}

export default BusinessInfo;
