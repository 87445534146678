import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { editBrand } from '../../../../features/InfoSlice/infoSlice';
import Loader from '../../../Shared/Loader';



const ExpenseHeadField = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [expenseHeads, setExpenseHeads] = useState([]);
    const [updateExpenseHead, setUpdateExpenseHead] = useState(null);
    const [loading, setLoading] = useState(true);
    const [expend, setExpend] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/rabbi-electric/getexpenseheads')
            .then(res => res.json())
            .then(data => {
                setExpenseHeads(data);
                setLoading(false)
            })
    }, [expenseHeads, setExpenseHeads, updateExpenseHead, setUpdateExpenseHead, dispatch])

    if (loading) {
        return <Loader />
    }

    const onSubmit = data => {
        const user = userAc?.email.split('@')[0];
        fetch('https://servers.clearsoftwares.net/rabbi-electric/addexpensehead', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({user,...data}),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Expense Head Added')
                reset();
            })

    }

    const handleDeleteExpenseHead = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://servers.clearsoftwares.net/rabbi-electric/getexpensehead/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdateExpenseHead(null);
                })
        }
    };

    const handleUpdateExpenseHead = (expenseHead) => {

        // fetch(`https://servers.clearsoftwares.net/rabbi-electric/getbrands/${brand._id}`)
        //     .then(res => res.json())
        //     .then(data => {
        //         dispatch(editBrand(data))
        //     })
        setUpdateExpenseHead(expenseHead);
    }
    const handleApprove=(expenseHead)=>{
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://servers.clearsoftwares.net/rabbi-electric/getexpensehead/${expenseHead._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({approvedBy})
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Expense Head Approved');
                // window.location.reload(false)
            })
    }
    return (
        <div className='shadow-2xl px-2 pb-5 my-5 text-xs'>
            {/* <button onClick={()=>setExpend(!expend)} className='btn my-8'>Add Expense Head</button> */}
            <form onSubmit={handleSubmit(onSubmit)} className={`container flex flex-col lg:flex-row md:flex-row gap-2 justify-center items-center`}>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Expense Head Name</span>
                    </label>
                    <input {...register("name")} type="text" placeholder="Type Expense Head Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                </div>
                <div class="form-control w-full max-w-xs">
                    <label class="label">
                        <span class="label-text text-sm">Status</span>
                    </label>
                    <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                        <option selected>Active</option>
                        <option>Inactive</option>
                    </select>
                </div>
                <input disabled={!userAc?.addExpenseHead} type="submit" value='Add Expense Head' className='btn bg-red-600 text-white lg:mt-9' />
            </form>

            {
                expenseHeads.length ? <div class="overflow-x-auto mt-8">
                    <table class="table w-1/2 mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL</th>
                                <th className='bg-red-600 text-white normal-case'>Expense Head Name</th>
                                <th className='bg-red-600 text-white normal-case'>Status</th>
                                <th className='bg-red-600 text-white normal-case'>Added By</th>
                                <th className='bg-red-600 text-white normal-case'>Approved By</th>
                                <th className='bg-red-600 text-white normal-case'>Edit</th>
                                <th className='bg-red-600 text-white normal-case'>Delete</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                expenseHeads.map((expenseHead, index) => <tr className='hover'>
                                    <td>{index + 1}</td>
                                    <td>{expenseHead.name}</td>
                                    <td> <button className={`btn btn-xs normal-case ${expenseHead.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{expenseHead?.status}</button> </td>
                                    <td><button className='btn btn-xs btn-primary text-white normal-case'>{expenseHead?.user ? expenseHead.user : "User"}</button></td>
                                    {userAc.approvalExpenseHead? <td>{expenseHead?.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{expenseHead?.approvedBy}</button>: <button onClick={()=>handleApprove(expenseHead)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td>:
                                   <td>{expenseHead.approvedBy?<button className='btn btn-xs btn-success text-white normal-case'>{expenseHead.approvedBy}</button>:<button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                    {userAc?.editExpenseHead && <td><button><label for="update-modal" onClick={() => handleUpdateExpenseHead(expenseHead)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                    {userAc?.deleteExpenseHead && <td><button onClick={() => handleDeleteExpenseHead(expenseHead._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div> : ""
            }
            {
                updateExpenseHead && <UpdateModal setUpdateExpenseHead={setUpdateExpenseHead} updateExpenseHead={updateExpenseHead}></UpdateModal>
            }
        </div>
    );
};

export default ExpenseHeadField;